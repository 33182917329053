
@tailwind components;

@layer components {
  .input-field {
    @apply block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-normal dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }
  .submit {
    background-color: rgb(126 34 206) !important;
    @apply focus:outline-none text-white font-bold bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 rounded-lg text-base px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900;
  }

  .gradient-border-color {
    border: 1px solid;
    border-color: linear-gradient(to right, rgb(0, 118, 208));
  }
}

.red-background {
  background: url('../Assests/Images/login-background.jpg');
  backdrop-filter: blur(5px);
}

.crayon-background {
  background-color: rgba(255, 255, 255, 0.78);
  border-radius: 12px;
}


.main-background {
  background: url('../Assests/Images/login-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.main-background > div {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.login-signup-state {
  @apply text-3xl w-1/2 py-4 flex justify-center items-center cursor-pointer;
}

.current-selected-state {
  font-weight: 600;
  background: #7abbf154;
  border-color: #38bdf8 !important;
  border: solid 2px;
}

