@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap');

body{
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.menu-items .active {
    border-image: linear-gradient(to right, #3490dc, #38bdf8);
    border-image-slice: 2;
    color: #3490dc;
  }
  .icons svg path {
    fill: #fff !important;
  }
  .imageTwo svg rect {
    fill:linear-gradient(to right, #3490dc, #38bdf8)
  }

  .leading-snug {
    line-height: 1.375 !important;
}

.text-gradient {
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #88CAFD, #B7FCF0);
  }
  .slick-track{
    display: flex !important;
    align-items: center !important;
  }